import React, { Component } from 'react'

export default class QuestionHeader extends Component {
    render() {
        return (
            <div>
            <div className="container">
                <img src="/images/logo_auto.png" className="centrar logo_auto" alt="logo_auto" />
                <div>
                    <div className=" alert alert-light Ramblar titulo borde-rojo col-10 col-md-12 center-text-desk" role="alert">
                        ¿Qué tan listo estás para acceder a un <a href="/" className=" Ramblab">CRÉDITO VEHICULAR?</a>
                    </div>
                </div>
                <div>
                    <div className="alert Santander  col-10 col-md-12" role="alert">
                        <p className="center-text-desk textos">Completa la encuesta y conoce los mejores tips para solicitar tu crédito y tener tu carro soñado.</p>
                    </div>
                </div>
               
            </div>
             <div className="container linea">
                    <span className=""></span>   
            </div>
            </div>
        )
    }
}
