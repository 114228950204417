/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getQuestionaryConfig = /* GraphQL */ `
  query GetQuestionaryConfig($id: ID!) {
    getQuestionaryConfig(id: $id) {
      id
      isShowReactCursorPositionLabel
      isCreateInteractionQuestionary
      isCreateInteractionQuestionaryButton
      layout
    }
  }
`;
export const listQuestionaryConfigs = /* GraphQL */ `
  query ListQuestionaryConfigs(
    $filter: ModelQuestionaryConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionaryConfigs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isShowReactCursorPositionLabel
        isCreateInteractionQuestionary
        isCreateInteractionQuestionaryButton
        layout
      }
      nextToken
    }
  }
`;
export const getQuestionary = /* GraphQL */ `
  query GetQuestionary($id: ID!) {
    getQuestionary(id: $id) {
      id
      name
      isEnable
      questions {
        items {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        nextToken
      }
      questionaryInteractions {
        items {
          id
          clientID
          utm
          ip
          browser
          questionaryStartTime
          questionaryEndTime
          isBrowser
          isMobile
          state_0
          state_1
          state_2
          questionaryID
        }
        nextToken
      }
    }
  }
`;
export const listQuestionarys = /* GraphQL */ `
  query ListQuestionarys(
    $filter: ModelQuestionaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionarys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        isEnable
        questions {
          items {
            id
            question
            orderNumber
            isEnable
              options {
              items {
                id
                orderNumber
                title
              }
          }
            }
        }
        questionaryInteractions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getQuestionaryInteraction = /* GraphQL */ `
  query GetQuestionaryInteraction($id: ID!) {
    getQuestionaryInteraction(id: $id) {
      id
      clientID
      utm
      ip
      browser
      questionaryStartTime
      questionaryEndTime
      isBrowser
      isMobile
      state_0
      state_1
      state_2
      questionaryID
      questionary {
        id
        name
        isEnable
        questions {
          nextToken
        }
        questionaryInteractions {
          nextToken
        }
      }
      interactions {
        items {
          id
          groupInteractionId
          type
          element
          epoch
          isMouseDetected
          isTouchDetected
          height
          width
          isActive
          isActiveClick
          isActiveTouch
          isPositionOutside
          x
          y
          distance
          speed
          speedAverage
          sumTimeMiliseconds
          dt
          sumDistance
          countOutside
          distance_questionary_point
          distance_left_button_point
          distance_right_button_point
          sumTimeMilisecondsBeforeNextQuestion
          questionID
          questionaryInteractionID
        }
        nextToken
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const listQuestionaryInteractions = /* GraphQL */ `
  query ListQuestionaryInteractions(
    $filter: ModelQuestionaryInteractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionaryInteractions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientID
        utm
        ip
        browser
        questionaryStartTime
        questionaryEndTime
        isBrowser
        isMobile
        state_0
        state_1
        state_2
        questionaryID
        questionary {
          id
          name
          isEnable
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      question
      questionaryID
      isEnable
      orderNumber
      questionary {
        id
        name
        isEnable
        questions {
          nextToken
        }
        questionaryInteractions {
          nextToken
        }
      }
      options {
        items {
          id
          title
          orderNumber
          questionID
        }
        nextToken
      }
      interactions {
        items {
          id
          groupInteractionId
          type
          element
          epoch
          isMouseDetected
          isTouchDetected
          height
          width
          isActive
          isActiveClick
          isActiveTouch
          isPositionOutside
          x
          y
          distance
          speed
          speedAverage
          sumTimeMiliseconds
          dt
          sumDistance
          countOutside
          distance_questionary_point
          distance_left_button_point
          distance_right_button_point
          sumTimeMilisecondsBeforeNextQuestion
          questionID
          questionaryInteractionID
        }
        nextToken
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOption = /* GraphQL */ `
  query GetOption($id: ID!) {
    getOption(id: $id) {
      id
      title
      orderNumber
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const listOptions = /* GraphQL */ `
  query ListOptions(
    $filter: ModelOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        orderNumber
        questionID
        question {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        questionAnswers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getQuestionAnswer = /* GraphQL */ `
  query GetQuestionAnswer($id: ID!) {
    getQuestionAnswer(id: $id) {
      id
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      optionID
      option {
        id
        title
        orderNumber
        questionID
        question {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        questionAnswers {
          nextToken
        }
      }
      questionaryInteractionID
      questionaryInteraction {
        id
        clientID
        utm
        ip
        browser
        questionaryStartTime
        questionaryEndTime
        isBrowser
        isMobile
        state_0
        state_1
        state_2
        questionaryID
        questionary {
          id
          name
          isEnable
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
    }
  }
`;
export const listQuestionAnswers = /* GraphQL */ `
  query ListQuestionAnswers(
    $filter: ModelQuestionAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionID
        question {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        optionID
        option {
          id
          title
          orderNumber
          questionID
        }
        questionaryInteractionID
        questionaryInteraction {
          id
          clientID
          utm
          ip
          browser
          questionaryStartTime
          questionaryEndTime
          isBrowser
          isMobile
          state_0
          state_1
          state_2
          questionaryID
        }
      }
      nextToken
    }
  }
`;
export const getInteraction = /* GraphQL */ `
  query GetInteraction($id: ID!) {
    getInteraction(id: $id) {
      id
      groupInteractionId
      type
      element
      epoch
      isMouseDetected
      isTouchDetected
      height
      width
      isActive
      isActiveClick
      isActiveTouch
      isPositionOutside
      x
      y
      distance
      speed
      speedAverage
      sumTimeMiliseconds
      dt
      sumDistance
      countOutside
      distance_questionary_point
      distance_left_button_point
      distance_right_button_point
      sumTimeMilisecondsBeforeNextQuestion
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      questionaryInteractionID
      questionaryInteraction {
        id
        clientID
        utm
        ip
        browser
        questionaryStartTime
        questionaryEndTime
        isBrowser
        isMobile
        state_0
        state_1
        state_2
        questionaryID
        questionary {
          id
          name
          isEnable
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
    }
  }
`;
export const listInteractions = /* GraphQL */ `
  query ListInteractions(
    $filter: ModelInteractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInteractions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupInteractionId
        type
        element
        epoch
        isMouseDetected
        isTouchDetected
        height
        width
        isActive
        isActiveClick
        isActiveTouch
        isPositionOutside
        x
        y
        distance
        speed
        speedAverage
        sumTimeMiliseconds
        dt
        sumDistance
        countOutside
        distance_questionary_point
        distance_left_button_point
        distance_right_button_point
        sumTimeMilisecondsBeforeNextQuestion
        questionID
        question {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        questionaryInteractionID
        questionaryInteraction {
          id
          clientID
          utm
          ip
          browser
          questionaryStartTime
          questionaryEndTime
          isBrowser
          isMobile
          state_0
          state_1
          state_2
          questionaryID
        }
      }
      nextToken
    }
  }
`;
