import React, { Component } from 'react'
import Start from './components/Start'
import Footer from './components/Footer'
import Questionary from './components/Questionary'
import Admon from './components/Admon/Admon'

// Amplify
// import { withAuthenticator } from 'aws-amplify-react'

// Routing
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

class App extends Component{
  constructor() {
    super();
    this.state = {
      x: 0,
      y: 0,
      epochTime: 0
    }
  }

  // _onMouseMove(e) {
  //   this.setState({ x: e.screenX, y: e.screenY, epochTime: Date.now() })
  // }

  
  render() {
    // const { x, y, epochTime} = this.state;
    return (   
      <Router>
        {/* <div className="" onMouseMove={this._onMouseMove.bind(this)}> */}
        <div className="">
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" />
          <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
          {/* <link
              rel="stylesheet"
              href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
            /> */}
          {/* <h1>Mouse coordinates: {x} {y} {epochTime}</h1> */}
          <Switch>
            <Route path="/" exact component={Start} />
            <Route path="/questionary" exact component={Questionary} />
            <Route path="/admon" exact component={Admon} />
          </Switch>

          <Footer></Footer>
        </div>
      </Router>
    )
  }
}
// export default withAuthenticator(App)
export default App