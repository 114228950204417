/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createQuestionaryConfig = /* GraphQL */ `
  mutation CreateQuestionaryConfig(
    $input: CreateQuestionaryConfigInput!
    $condition: ModelQuestionaryConfigConditionInput
  ) {
    createQuestionaryConfig(input: $input, condition: $condition) {
      id
      isShowReactCursorPositionLabel
      isCreateInteractionQuestionary
      isCreateInteractionQuestionaryButton
      layout
    }
  }
`;
export const updateQuestionaryConfig = /* GraphQL */ `
  mutation UpdateQuestionaryConfig(
    $input: UpdateQuestionaryConfigInput!
    $condition: ModelQuestionaryConfigConditionInput
  ) {
    updateQuestionaryConfig(input: $input, condition: $condition) {
      id
      isShowReactCursorPositionLabel
      isCreateInteractionQuestionary
      isCreateInteractionQuestionaryButton
      layout
    }
  }
`;
export const deleteQuestionaryConfig = /* GraphQL */ `
  mutation DeleteQuestionaryConfig(
    $input: DeleteQuestionaryConfigInput!
    $condition: ModelQuestionaryConfigConditionInput
  ) {
    deleteQuestionaryConfig(input: $input, condition: $condition) {
      id
      isShowReactCursorPositionLabel
      isCreateInteractionQuestionary
      isCreateInteractionQuestionaryButton
      layout
    }
  }
`;
export const createQuestionary = /* GraphQL */ `
  mutation CreateQuestionary(
    $input: CreateQuestionaryInput!
    $condition: ModelQuestionaryConditionInput
  ) {
    createQuestionary(input: $input, condition: $condition) {
      id
      name
      isEnable
      questions {
        items {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        nextToken
      }
      questionaryInteractions {
        items {
          id
          clientID
          utm
          ip
          browser
          questionaryStartTime
          questionaryEndTime
          isBrowser
          isMobile
          state_0
          state_1
          state_2
          questionaryID
        }
        nextToken
      }
    }
  }
`;
export const updateQuestionary = /* GraphQL */ `
  mutation UpdateQuestionary(
    $input: UpdateQuestionaryInput!
    $condition: ModelQuestionaryConditionInput
  ) {
    updateQuestionary(input: $input, condition: $condition) {
      id
      name
      isEnable
      questions {
        items {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        nextToken
      }
      questionaryInteractions {
        items {
          id
          clientID
          utm
          ip
          browser
          questionaryStartTime
          questionaryEndTime
          isBrowser
          isMobile
          state_0
          state_1
          state_2
          questionaryID
        }
        nextToken
      }
    }
  }
`;
export const deleteQuestionary = /* GraphQL */ `
  mutation DeleteQuestionary(
    $input: DeleteQuestionaryInput!
    $condition: ModelQuestionaryConditionInput
  ) {
    deleteQuestionary(input: $input, condition: $condition) {
      id
      name
      isEnable
      questions {
        items {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        nextToken
      }
      questionaryInteractions {
        items {
          id
          clientID
          utm
          ip
          browser
          questionaryStartTime
          questionaryEndTime
          isBrowser
          isMobile
          state_0
          state_1
          state_2
          questionaryID
        }
        nextToken
      }
    }
  }
`;
export const createQuestionaryInteraction = /* GraphQL */ `
  mutation CreateQuestionaryInteraction(
    $input: CreateQuestionaryInteractionInput!
    $condition: ModelQuestionaryInteractionConditionInput
  ) {
    createQuestionaryInteraction(input: $input, condition: $condition) {
      id
      clientID
      utm
      ip
      browser
      questionaryStartTime
      questionaryEndTime
      isBrowser
      isMobile
      state_0
      state_1
      state_2
      questionaryID
      questionary {
        id
        name
        isEnable
        questions {
          nextToken
        }
        questionaryInteractions {
          nextToken
        }
      }
      interactions {
        items {
          id
          groupInteractionId
          type
          element
          epoch
          isMouseDetected
          isTouchDetected
          height
          width
          isActive
          isActiveClick
          isActiveTouch
          isPositionOutside
          x
          y
          distance
          speed
          speedAverage
          sumTimeMiliseconds
          dt
          sumDistance
          countOutside
          distance_questionary_point
          distance_left_button_point
          distance_right_button_point
          sumTimeMilisecondsBeforeNextQuestion
          questionID
          questionaryInteractionID
        }
        nextToken
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const updateQuestionaryInteraction = /* GraphQL */ `
  mutation UpdateQuestionaryInteraction(
    $input: UpdateQuestionaryInteractionInput!
    $condition: ModelQuestionaryInteractionConditionInput
  ) {
    updateQuestionaryInteraction(input: $input, condition: $condition) {
      id
      clientID
      utm
      ip
      browser
      questionaryStartTime
      questionaryEndTime
      isBrowser
      isMobile
      state_0
      state_1
      state_2
      questionaryID
      questionary {
        id
        name
        isEnable
        questions {
          nextToken
        }
        questionaryInteractions {
          nextToken
        }
      }
      interactions {
        items {
          id
          groupInteractionId
          type
          element
          epoch
          isMouseDetected
          isTouchDetected
          height
          width
          isActive
          isActiveClick
          isActiveTouch
          isPositionOutside
          x
          y
          distance
          speed
          speedAverage
          sumTimeMiliseconds
          dt
          sumDistance
          countOutside
          distance_questionary_point
          distance_left_button_point
          distance_right_button_point
          sumTimeMilisecondsBeforeNextQuestion
          questionID
          questionaryInteractionID
        }
        nextToken
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const deleteQuestionaryInteraction = /* GraphQL */ `
  mutation DeleteQuestionaryInteraction(
    $input: DeleteQuestionaryInteractionInput!
    $condition: ModelQuestionaryInteractionConditionInput
  ) {
    deleteQuestionaryInteraction(input: $input, condition: $condition) {
      id
      clientID
      utm
      ip
      browser
      questionaryStartTime
      questionaryEndTime
      isBrowser
      isMobile
      state_0
      state_1
      state_2
      questionaryID
      questionary {
        id
        name
        isEnable
        questions {
          nextToken
        }
        questionaryInteractions {
          nextToken
        }
      }
      interactions {
        items {
          id
          groupInteractionId
          type
          element
          epoch
          isMouseDetected
          isTouchDetected
          height
          width
          isActive
          isActiveClick
          isActiveTouch
          isPositionOutside
          x
          y
          distance
          speed
          speedAverage
          sumTimeMiliseconds
          dt
          sumDistance
          countOutside
          distance_questionary_point
          distance_left_button_point
          distance_right_button_point
          sumTimeMilisecondsBeforeNextQuestion
          questionID
          questionaryInteractionID
        }
        nextToken
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      question
      questionaryID
      isEnable
      orderNumber
      questionary {
        id
        name
        isEnable
        questions {
          nextToken
        }
        questionaryInteractions {
          nextToken
        }
      }
      options {
        items {
          id
          title
          orderNumber
          questionID
        }
        nextToken
      }
      interactions {
        items {
          id
          groupInteractionId
          type
          element
          epoch
          isMouseDetected
          isTouchDetected
          height
          width
          isActive
          isActiveClick
          isActiveTouch
          isPositionOutside
          x
          y
          distance
          speed
          speedAverage
          sumTimeMiliseconds
          dt
          sumDistance
          countOutside
          distance_questionary_point
          distance_left_button_point
          distance_right_button_point
          sumTimeMilisecondsBeforeNextQuestion
          questionID
          questionaryInteractionID
        }
        nextToken
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      question
      questionaryID
      isEnable
      orderNumber
      questionary {
        id
        name
        isEnable
        questions {
          nextToken
        }
        questionaryInteractions {
          nextToken
        }
      }
      options {
        items {
          id
          title
          orderNumber
          questionID
        }
        nextToken
      }
      interactions {
        items {
          id
          groupInteractionId
          type
          element
          epoch
          isMouseDetected
          isTouchDetected
          height
          width
          isActive
          isActiveClick
          isActiveTouch
          isPositionOutside
          x
          y
          distance
          speed
          speedAverage
          sumTimeMiliseconds
          dt
          sumDistance
          countOutside
          distance_questionary_point
          distance_left_button_point
          distance_right_button_point
          sumTimeMilisecondsBeforeNextQuestion
          questionID
          questionaryInteractionID
        }
        nextToken
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      question
      questionaryID
      isEnable
      orderNumber
      questionary {
        id
        name
        isEnable
        questions {
          nextToken
        }
        questionaryInteractions {
          nextToken
        }
      }
      options {
        items {
          id
          title
          orderNumber
          questionID
        }
        nextToken
      }
      interactions {
        items {
          id
          groupInteractionId
          type
          element
          epoch
          isMouseDetected
          isTouchDetected
          height
          width
          isActive
          isActiveClick
          isActiveTouch
          isPositionOutside
          x
          y
          distance
          speed
          speedAverage
          sumTimeMiliseconds
          dt
          sumDistance
          countOutside
          distance_questionary_point
          distance_left_button_point
          distance_right_button_point
          sumTimeMilisecondsBeforeNextQuestion
          questionID
          questionaryInteractionID
        }
        nextToken
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const createOption = /* GraphQL */ `
  mutation CreateOption(
    $input: CreateOptionInput!
    $condition: ModelOptionConditionInput
  ) {
    createOption(input: $input, condition: $condition) {
      id
      title
      orderNumber
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const updateOption = /* GraphQL */ `
  mutation UpdateOption(
    $input: UpdateOptionInput!
    $condition: ModelOptionConditionInput
  ) {
    updateOption(input: $input, condition: $condition) {
      id
      title
      orderNumber
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const deleteOption = /* GraphQL */ `
  mutation DeleteOption(
    $input: DeleteOptionInput!
    $condition: ModelOptionConditionInput
  ) {
    deleteOption(input: $input, condition: $condition) {
      id
      title
      orderNumber
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const createQuestionAnswer = /* GraphQL */ `
  mutation CreateQuestionAnswer(
    $input: CreateQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    createQuestionAnswer(input: $input, condition: $condition) {
      id
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      optionID
      option {
        id
        title
        orderNumber
        questionID
        question {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        questionAnswers {
          nextToken
        }
      }
      questionaryInteractionID
      questionaryInteraction {
        id
        clientID
        utm
        ip
        browser
        questionaryStartTime
        questionaryEndTime
        isBrowser
        isMobile
        state_0
        state_1
        state_2
        questionaryID
        questionary {
          id
          name
          isEnable
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
    }
  }
`;
export const updateQuestionAnswer = /* GraphQL */ `
  mutation UpdateQuestionAnswer(
    $input: UpdateQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    updateQuestionAnswer(input: $input, condition: $condition) {
      id
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      optionID
      option {
        id
        title
        orderNumber
        questionID
        question {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        questionAnswers {
          nextToken
        }
      }
      questionaryInteractionID
      questionaryInteraction {
        id
        clientID
        utm
        ip
        browser
        questionaryStartTime
        questionaryEndTime
        isBrowser
        isMobile
        state_0
        state_1
        state_2
        questionaryID
        questionary {
          id
          name
          isEnable
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
    }
  }
`;
export const deleteQuestionAnswer = /* GraphQL */ `
  mutation DeleteQuestionAnswer(
    $input: DeleteQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    deleteQuestionAnswer(input: $input, condition: $condition) {
      id
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      optionID
      option {
        id
        title
        orderNumber
        questionID
        question {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        questionAnswers {
          nextToken
        }
      }
      questionaryInteractionID
      questionaryInteraction {
        id
        clientID
        utm
        ip
        browser
        questionaryStartTime
        questionaryEndTime
        isBrowser
        isMobile
        state_0
        state_1
        state_2
        questionaryID
        questionary {
          id
          name
          isEnable
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
    }
  }
`;
export const createInteraction = /* GraphQL */ `
  mutation CreateInteraction(
    $input: CreateInteractionInput!
    $condition: ModelInteractionConditionInput
  ) {
    createInteraction(input: $input, condition: $condition) {
      id
      groupInteractionId
      type
      element
      epoch
      isMouseDetected
      isTouchDetected
      height
      width
      isActive
      isActiveClick
      isActiveTouch
      isPositionOutside
      x
      y
      distance
      speed
      speedAverage
      sumTimeMiliseconds
      dt
      sumDistance
      countOutside
      distance_questionary_point
      distance_left_button_point
      distance_right_button_point
      sumTimeMilisecondsBeforeNextQuestion
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      questionaryInteractionID
      questionaryInteraction {
        id
        clientID
        utm
        ip
        browser
        questionaryStartTime
        questionaryEndTime
        isBrowser
        isMobile
        state_0
        state_1
        state_2
        questionaryID
        questionary {
          id
          name
          isEnable
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
    }
  }
`;
export const updateInteraction = /* GraphQL */ `
  mutation UpdateInteraction(
    $input: UpdateInteractionInput!
    $condition: ModelInteractionConditionInput
  ) {
    updateInteraction(input: $input, condition: $condition) {
      id
      groupInteractionId
      type
      element
      epoch
      isMouseDetected
      isTouchDetected
      height
      width
      isActive
      isActiveClick
      isActiveTouch
      isPositionOutside
      x
      y
      distance
      speed
      speedAverage
      sumTimeMiliseconds
      dt
      sumDistance
      countOutside
      distance_questionary_point
      distance_left_button_point
      distance_right_button_point
      sumTimeMilisecondsBeforeNextQuestion
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      questionaryInteractionID
      questionaryInteraction {
        id
        clientID
        utm
        ip
        browser
        questionaryStartTime
        questionaryEndTime
        isBrowser
        isMobile
        state_0
        state_1
        state_2
        questionaryID
        questionary {
          id
          name
          isEnable
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
    }
  }
`;
export const deleteInteraction = /* GraphQL */ `
  mutation DeleteInteraction(
    $input: DeleteInteractionInput!
    $condition: ModelInteractionConditionInput
  ) {
    deleteInteraction(input: $input, condition: $condition) {
      id
      groupInteractionId
      type
      element
      epoch
      isMouseDetected
      isTouchDetected
      height
      width
      isActive
      isActiveClick
      isActiveTouch
      isPositionOutside
      x
      y
      distance
      speed
      speedAverage
      sumTimeMiliseconds
      dt
      sumDistance
      countOutside
      distance_questionary_point
      distance_left_button_point
      distance_right_button_point
      sumTimeMilisecondsBeforeNextQuestion
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      questionaryInteractionID
      questionaryInteraction {
        id
        clientID
        utm
        ip
        browser
        questionaryStartTime
        questionaryEndTime
        isBrowser
        isMobile
        state_0
        state_1
        state_2
        questionaryID
        questionary {
          id
          name
          isEnable
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
    }
  }
`;
