/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:dc62bdde-5c90-4fd4-80f0-41004f724110",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_HxQWbgLhP",
    "aws_user_pools_web_client_id": "2t4khom0kkv7ddtmcn6ubtltsq",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://rop52fode5aqfnb4cfyd2okbza.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qcf5cgzum5azjfokj2rgsafh2y"
};


export default awsmobile;
