import React, { Component } from 'react'

// Auth
import { withAuthenticator } from 'aws-amplify-react';

// Routing
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'

// Components

import Header from './Header/Header'
import Footer from './Footer/Footer'
import QuestionaryConfig from './QuestionaryConfig/QuestionaryConfig'

class Admon extends Component {

    constructor() {
        super()
        this.state = {

        }
    }

    render() {
        return (
            <Router>
                <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossOrigin="anonymous"></script>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js" integrity="sha384-ZMP7rVo3mIykV+2+9J3UJ46jBk0WLaUAdn689aCwoqbBJiSnjAK/l8WvCWPIPm49" crossOrigin="anonymous"></script>
                <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js" integrity="sha384-ChfqqxuZUCnJSK3+MXmPNIyE6ZbWh2IMqE241rYiqJxyMiZ6OW/JmZQ5stwEULTy" crossOrigin="anonymous"></script>
                <Header/>
                <div className="container">
                    <Switch>
                        <Route path="/questionaryConfig" component={QuestionaryConfig}/>
                        {/* <Route path="/createFreeRegister" component={CreateFreeRegister}/>
                        <Route path="/registers" component={DisplayRegisters}/>
                        <Route path="/createLocation" component={CreateLocation}/>
                        <Route path="/createCompany" component={CreateCompany}/>
                        <Route path="/createInsurance" component={CreateInsurance}/>
                        <Route path="/createTechnomec" component={CreateTechnomec}/> */}
                    </Switch>
                </div>
                <Footer/>
            </Router>
        )
    }
}


export default withAuthenticator(Admon, {includeGreetings: true})