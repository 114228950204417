import React, { Component } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { updateQuestionaryConfig } from '../../../graphql/mutations'
// import { onUpdateQuestionaryConfig} from '../../../graphql/subscriptions'
import { listQuestionaryConfigs} from '../../../graphql/queries'

class CreateQuestionaryConf extends Component {

  constructor() {
    super()
    this.state = {
        questionaryConfig: null,
        isShowReactCursorPositionLabel: false,
        isCreateInteractionQuestionary: false,
        isCreateInteractionQuestionaryButton: false,
        layout: "",
        
    }
  }

  componentDidMount = async () => {
    await this.loadQuestionaryConfig()
    
  }

  loadQuestionaryConfig = async () => {
    const result = await API.graphql(graphqlOperation(listQuestionaryConfigs, {authMode: 'API_KEY'}))
    if (result.data.listQuestionaryConfigs.items.length > 0) {
        this.setState({
            isShowReactCursorPositionLabel: result.data.listQuestionaryConfigs.items[0].isShowReactCursorPositionLabel,
            isCreateInteractionQuestionary: result.data.listQuestionaryConfigs.items[0].isCreateInteractionQuestionary,
            isCreateInteractionQuestionaryButton: result.data.listQuestionaryConfigs.items[0].isCreateInteractionQuestionaryButton,
        }) 
    }
    console.log(" Questionary Config: ", result.data.listQuestionaryConfigs.items)
    console.log(" loadQuestionaryConfig this.state: ", this.state)
}

  handleUpdateQuestionaryConfig = async event => {
    event.preventDefault()

    // this.updatedQuestionaryConfig = API.graphql(graphqlOperation(onUpdateQuestionaryConfig))
    // .subscribe({
    //     next: questionaryConfigData => {
    //       this.setState({ questionaryConfig: questionaryConfigData.value.data.onUpdateQuestionaryConfig});
    //   }
    // })
    
    console.log('### handleAddQuestionaryConfig, this.state: ', this.state)

    const input = {
        isShowReactCursorPositionLabel: this.state.isShowReactCursorPositionLabel,
        isCreateInteractionQuestionary: this.state.isCreateInteractionQuestionary,
        isCreateInteractionQuestionaryButton: this.state.isCreateInteractionQuestionaryButton

    }

    console.log('### handleAddRegister, input: ', input)

    await API.graphql(graphqlOperation(updateQuestionaryConfig, { input }));

  }


  // Fillout the Post data when change
  handleChangeRegister = event => this.setState({  
    [event.target.name] : event.target.value.toUpperCase() 
  }
  )

  render() {
    return (
      <form className="" onSubmit={this.handleUpdateQuestionaryConfig} >
        
        <div className="row">

          <div className="col-md-6">
            <input style={{ font: '19px' }}
              type="text" placeholder="Placa del vehículo"
              name="frVehTag"
              required
              value={this.state.frVehTag}
              onChange={this.handleChangeRegister}
            />
          </div>
            
          <div className="col-md-6">
            
            <div>
              <p>Foto del Vehículo</p>
              <input
                type="file"
                onChange={this.identifyLabel}>
              </input>
              <p>{this.state.response}</p>
            </div>

            <div>
              <img src={this.state.actualFile}  alt="actual-vehicle-img"/>
            </div>
            
          </div>
        </div>

        <input disabled={this.state.isSubmitEnable}  type="submit" className="btn" style={{ fontSize: '19px'}}/>

        {/* <table>
          <tbody>
            {this.state.freeRegisters.map(register => (
              <tr key={register.id}>
                <td>
                  {register.frVehTag}
                </td>
                <td>
                  {register.frVehPhotoSrc}
                </td>
                <td>
                  {register.createdAt}
                </td>
              </tr>
            ))}
          </tbody>
        </table> */}

        </form>

        
      );
  }
}

export default CreateQuestionaryConf