import React, { Component } from 'react'
import { ExternalLink } from 'react-external-link'

export default class End extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            linkTo: "https://aliados.santanderconsumer.co/santander/?roisense="+props.clientID+"&fuente=enriquecido ",
        }
    }


    render() {
        return (
            <div>
                 <div className="container">
                    <img src="/images/logo_auto.png" className="centrar logo_auto" alt="logo_auto"/>
                    <div>
                        <div className="alert alert-light Ramblab titulo borde-rojo col-xs-12" role="alert">
                            ¡Muy bien! Aquí algunos tips que podrían ayudarte: 
                        </div>
                    </div>
                </div>
                    

                    <div className="container">
                        <ul>
                            <li className="list-check"><img src="/images/chulo.png" className="chulo" alt="chulo"/><p className="text-chulo">Importante contar con un puntaje mínimo de 700 en centrales de riesgo.</p></li>
                            <li className="list-check"><img src="/images/chulo.png" className="chulo" alt="chulo"/><p className="text-chulo">Destina al menos $500.000 pesos mensuales, libres de otros gastos, para el pago de tu obligación financiera.</p></li>
                            <li className="list-check"><img src="/images/chulo.png" className="chulo" alt="chulo"/><p className="text-chulo">Cuenta con una estabilidad laboral de al menos 12 meses, además de los respectivos documentos que lo avalen, sin importar si eres empleado o independiente.</p></li>
                            <li className="list-check"><img src="/images/chulo.png" className="chulo" alt="chulo"/><p className="text-chulo">No ocupes el límite del cupo de tus tarjetas de crédito, esto puede no verse bien ante las entidades financieras.</p></li>
                        </ul>
                        </div>
                            
                        <div className="container">

                            <ExternalLink href={this.state.linkTo} className="botonchulo Ramblab centrar text-end text-center" >
                                <span>EMPEZAR AHORA</span>
                            </ExternalLink>
                        
                            {/* <a to={this.state.linkTo} className="botonchulo Ramblab centrar text-end text-center">EMPEZAR AHORA<i className="material-icons">check</i></a> */}
                            <p className="text-end"><strong>¡Con estas recomendaciones te invitamos a que evalúes si tu crédito es viable en 1 minuto y sin papeles!<br />
                        Comunicarme con partner financiero.</strong></p>
                        
                        </div>
            </div>
        )
    }
}
