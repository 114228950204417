import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div className="fix-desktop">
                <div className="container  footer-global">
                    <div className="">
                        <div className="col-xs-6 col-md-6  Ramblar textsan">
                            Partner financiero
                        </div>
                        <div className="col-xs-6 col-md-6 ">
                            <img src="/images/logo_santander.png" className=" logo_san centrar " width="100" alt="logo_santander"/>
                        </div>
                    </div>
                </div>
                    <div className="container-fluid">
                        <div className="row footer">
                            <div className=" text-center">
                                <p>Ed. Workzone, Calle 99 N.11B – 66 Piso 2 | Of. Bronce - B.<br />Chicó Norte | 312-4967458<br /> © PlanAuto Todos los derechos reservados.</p>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}
