import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Start extends Component {
    constructor() {
        super()
        this.state = {
            utm: ""
        }
    }
    
    componentDidMount() {
        const { location: { search } } = this.props
        console.log('### componentDidMount: search', search)
        this.setState({utm: search})
        localStorage.setItem('utm', search)
    }

    render() {
        return (
            <div>
                <div className="container">
                    <img src="/images/logo_auto.png" className="centrar logo_auto" alt="logo_auto"/>
                    <div>
                        <div className=" center-text-desk alert alert-light Ramblar titulo borde-rojo col-10 col-md-12" role="alert">
                            ¿Qué tan listo estás para acceder a un <a href="/" className=" Ramblab">CRÉDITO VEHICULAR?</a> 
                        </div>
                    </div>
    
                    <div>
                        <div className="alert Santander  col-10 col-md-12" role="alert">
                            <p className="center-text-desk textos">Completa la encuesta y conoce los mejores tips para solicitar tu crédito y tener tu carro soñado.</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div>
                        <Link to="/questionary" className="botonchulo Ramblab centrar" >INICIAR ENCUESTA<i className="material-icons">check</i></Link>
                        {/* <button type="button" class="btn btn-primary">INICIAR ENCUESTA</button> */}
                    </div>
                </div>
            </div>
        )
    }
}
