import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class NavBar extends Component {
    render() {
        return (  
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <a className="navbar-brand" href="/"><img src="images/logoMUNDIAL.png" alt="Logo"/></a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
    
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item active">
                    <Link className="nav-link" to="/questionaryConfig"> Configuración</Link>
                  </li>
    
                  {/* <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Listas
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <Link className="dropdown-item" to="/questionaryConfig">Questionary Config</Link>
                      <Link className="dropdown-item" to="/createCompany">Crear Empresa</Link>
                      <Link className="dropdown-item" to="/createInsurance">Crear Seguro</Link>
                      <Link className="dropdown-item" to="/createInsurance">Crear Seguro</Link>
                      <Link className="dropdown-item" to="/CreateTechnomec">Crear Empresa Revisión Tecnomecánica</Link>
                    </div>
                  </li>
    
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Registros
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <Link className="dropdown-item" to="/createRegister">Crear Registro</Link>
                      <Link className="dropdown-item" to="/createFreeRegister">Crear Free Registro</Link>
                    </div>
                  </li>
    
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Reportes
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <Link className="dropdown-item" to="/registers">Registros</Link>
                    </div>
                  </li> */}
                  
                </ul>
              </div>
            </nav>
          </div>  
          
        )
      }
}
export default NavBar